<template>
  <v-dialog
    v-model="show_resource_modal"
    :max-width="maxPopupWidth"
    @click:outside="closePopup"
    persistent
  >
    <v-card>
      <v-toolbar dark flat color="primary" dense>
        <v-toolbar-title>{{$t('common.resources')}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closePopup">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-row no-gutters>
        <v-col cols="12">
          <v-card flat>
            <v-card-text class="px-0 py-0">
              <v-list>
                <div v-for="(item, index) in resource_list" :key="index">
                  <v-list-item>
                    <v-btn color="primary" class="mx-1" @click="addResourceView(item.resource_id)" small :href="item.file" target="_blank">
                      {{$t('action.view')}}
                    </v-btn>
                    <v-list-item-content class="mx-4">
                      <v-list-item-title>{{item.title}}</v-list-item-title>
                      <v-list-item-subtitle>{{item.description}}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn icon v-if="item.tote_bag">
                        <v-icon color="grey">mdi-briefcase-check-outline</v-icon>
                      </v-btn>
                      <v-btn icon @click="addToteBag(item.resource_id)" v-else>
                        <v-icon color="primary">mdi-briefcase-plus-outline</v-icon>
                      </v-btn>

                    </v-list-item-action>
                  </v-list-item>
                  <v-divider v-if="index != resource_list.length - 1"></v-divider>
                </div>
                <div v-if="resource_list.length == 0">
                  {{$t('common.not_found')}}
                </div>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "@/helper/axios";
import { mapState,mapActions,mapMutations } from 'vuex'
export default {
  name: "ResourcePopup",
  data() {
    return {
      selected : []
    };
  },
  watch: {
    show_resource_modal(value) {
      if (value) {
        this.fetchResources();
      }
    },
  },
  computed: {
    ...mapState("resource", ["show_resource_modal", "resource_list"]),
    ...mapState("utils", ["maxPopupWidth"]),
  },
  methods: {
    ...mapActions("resource", ["fetchResources"]),
    ...mapMutations("resource", ["setResourceData"]),
    ...mapMutations("utils", ["setAlert"]),
    addResourceView(resource_id) {
      let _self = this;
      axios
        .post("/resource/add_view",{resource_id: resource_id})
        .then(function () {

        })
        .catch(function (e) {
          console.log(e);
        });
      _self.loading = false;
    },
    closePopup() {
      this.setResourceData({
        key: "show_resource_modal",
        data: false,
      });
      this.setResourceData({
        key: "resource_list",
        data: {},
      });
      this.setResourceData({
        key: "total_resource",
        data: 0,
      });
      this.setResourceData({
        key: "resource_start_from",
        data: 0,
      });
    },
    addToteBag(resource_id) {
      let _self = this;
      this.loading = "secondary";
      axios.post(`tote_bag/add`, {content_id:resource_id, content_type:2})
        .then((response) => {
          let response_data = response.data;
          if (response_data.status) {
            _self.fetchResources()
            _self.setAlert({
              show: true,
              variant: "success",
              message: response_data.message,
              dismissCountDown: 5000,
            });
          }
          else {
            let errors = response_data.error;
            for (let key in errors) {
              _self.setAlert({
                show: true,
                variant: "danger",
                message: errors[key].message,
                dismissCountDown: 5000,
              });
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
  },
  mounted: function () {},
};
</script>