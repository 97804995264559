<template>
    <v-card
      class="blue-grey lighten-5 minimized-session"
      ref="video-card"
      :width="width"
      :height="height"
    >
      <v-card-title
        class="pa-0 d-flex justify-end success darken-2 md-toolbar"
        ref="drag-start"
      >
        <span class="white--text ml-1 text-body-2 col-10 text-truncate pa-1"
          >{{ vendor_details.company_name }}'s public chat</span
        >
        <v-spacer></v-spacer>
        <v-btn depressed small color="success darken-2" @click="closeWindow">
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text
        class="chat-screen d-flex flex-column-reverse grey lighten-4 pa-0"
      >
        <v-card
          v-for="message in public_messages"
          :key="message.public_msg_id"
          flat
          class="grey lighten-4"
        >
          <v-list-item
            :key="message.public_msg_id"
            v-if="message.attendee_id != myself.attendee_id"
            class=""
          >
            <v-list-item-avatar class="align-self-start mr-2 elevation-1">
              <v-avatar size="40">
                <v-img :src="message.attendee_data.profile_pic"></v-img>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content class="received-message">
              <v-card color="success" class="flex-none">
                <v-card-text class="white--text pa-2 d-flex flex-column">
                  <span class="text-caption name"
                    >{{ message.attendee_data.first_name }} {{ message.attendee_data.last_name }}
                    <v-icon dark title="Representative" size="14" v-if="!message.add_by_attendee"
                      >mdi-shield-star-outline</v-icon
                    >
                    <v-btn
                      small
                      icon
                      color="red darken-2"
                      style="float:right;top:0px"
                      class="pa-0"
                      v-if="is_representative && message.add_by_attendee"
                      @click="confrimDeleteMessage(message.public_msg_id)"
                    >
                      <v-icon>
                        mdi-delete-circle-outline
                      </v-icon>
                    </v-btn>
                  </span>
                  <span class="align-self-start text-subtitle-1 chat-message">{{
                    message.message
                  }}</span>
                  <span class="text-caption font-italic align-self-end">{{
                    getTime(message.created_at)
                  }}</span>
                </v-card-text>
              </v-card>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-else :key="message.public_msg_id">
            <v-list-item-content class="sent-message justify-end">
              <v-card color="primary" class="flex-none">
                <v-card-text class="white--text pa-2 d-flex flex-column">
                  <span class="text-subtitle-1 chat-message">{{
                    message.message
                  }}</span>
                  <span class="text-caption font-italic align-self-start">{{
                    getTime(message.created_at)
                  }}</span>
                </v-card-text>
              </v-card>
            </v-list-item-content>
            <v-list-item-avatar class="align-self-start ml-2 elevation-1">
              <v-img :src="message.attendee_data.profile_pic"></v-img>
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-card-text>
      <v-card-actions class="chat-footer success lighten-5 pa-0">
        <v-row class="d-flex flex-row justify-space-between" no-gutters>
          <v-col cols="12">
            <v-textarea
              placeholder="Type your message"
              v-model="new_message"
              @keydown.enter.exact.prevent
              @keyup.enter.exact="sendMessage()"
              @keydown.enter.shift.exact="newlineMessage"
              :loading="send_loading"
              hide-details=""
              rows="2"
              autofocus
              class="px-2"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-actions>
      <v-dialog v-model="delete_dialog" max-width="250">
        <v-card>
          <v-card-text class="py-2">
            <span class="">Sure want to delete message?</span>
          </v-card-text>
          <v-card-actions>
            <v-btn small color="green" dark @click="delete_dialog = false">cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn small color="red" dark @click="deleteMessage">ok</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
</template>

<script>
import axios from "@/helper/axios";
import { mapState, mapMutations } from "vuex";
export default {
  name: "MinimizedSession",
  components: {  },
  data() {
    return {
      width: 350,
      height: 400,
      x: 0,
      y: 0,
      top: 10,
      left: 10,
      public_messages: [],
      new_message: "",
      send_loading: false,
      socket_messages: [],
      subscription: null,
      activeUsers: 0,
      is_representative: null,
      delete_dialog : false,
      selected_message : '',
    };
  },
  computed: {
    ...mapState("public_chat", ["show_public_chat_modal"]),
    ...mapState("vendor", ["vendor_details"]),
    myself() {
      return JSON.parse(localStorage.getItem("pinnacle_user"));
    },
    newMessageWithoutSpace() {
      return this.new_message.trim();
    },
    messageLength() {
      return this.newMessageWithoutSpace.length;
    },
    reverse_messages() {
      let messages = this.public_messages;
      let msgArray = [];
      for (let i = messages.length; i--; ) {
        msgArray.push(messages[i]);
      }
      return msgArray;
    },
  },
  methods: {
    ...mapMutations("public_chat", ["setPublicChatData"]),
    setSocketMessage(data) {
      this.public_messages.unshift(data);
    },
    confrimDeleteMessage(message_id) {
      this.selected_message = message_id
      this.delete_dialog = true
    },
    deleteMessage() {
      this.send_loading = true;
      let data = {
        message_id: this.selected_message,
      };
      axios
        .post("/vendor_public/delete_message", data)
        .then(() => {
          this.getPublicMessages();
        })
        .then(() => {
          this.send_loading = false;
          this.delete_dialog = false
          this.selected_message = null
        });
    },
    getTime(time) {
      let msg_date = this.moment(time);
      let today = this.moment(new Date());
      if (msg_date.isSame(today, "day")) {
        return this.moment(time).format("hh:mm A");
      } else {
        return msg_date.format("DD, MMM hh:mm A");
      }
    },
    newlineMessage() {
      this.new_message = `${this.new_message}`;
    },
    sendMessage() {
      if (this.newMessageWithoutSpace.length && this.messageLength <= 400) {
        this.send_loading = true;
        let data = {
          message: this.newMessageWithoutSpace,
          vendor_id: this.vendor_details.vendor_id,
        };
        axios
          .post("/vendor_public/add_message", data)
          .then(() => {
            this.new_message = "";
            // this.session_message.unshift(data.data.data)
          })
          .then(() => {
            this.send_loading = false;
          });
      }
    },
    getPublicMessages() {
      axios
        .get("/vendor_public/message_list", {
          params: {
            vendor_id: this.vendor_details.vendor_id,
          },
        })
        .then((data) => {
          this.public_messages = data.data.data;
          this.is_representative = data.data.is_representative
        });
    },
    closeWindow() {
      this.setPublicChatData({
        key: "show_public_chat_modal",
        data: false,
      });
    },
    resize: function (d) {
      this.width = d.width;
      this.height = d.height;
      this.top = d.top;
      this.left = d.left;
    }
  },
  mounted: function () {
    this.getPublicMessages()
    this.x = document.documentElement.scrollTop + 150;
    this.y = (this.$vuetify.breakpoint.width * 38 / 100);
    this.subscription = this.$ws.socket.getSubscription(
      `public_chat:${this.vendor_details.vendor_id}`
    );
    if (!this.subscription) {
      this.subscription = this.$ws.subscribe(
        `public_chat:${this.vendor_details.vendor_id}`
      );
      this.$ws.$on(
        `public_chat:${this.vendor_details.vendor_id}|new_message`,
        this.setSocketMessage
      );
    } else {
      this.$ws.$on(
        `public_chat:${this.vendor_details.vendor_id}|new_message`,
        this.setSocketMessage
      );
    }
  },
  beforeDestroy() {
    this.closeWindow();
    this.$ws.$off(`public_chat:${this.vendor_details.vendor_id}`);
    this.subscription.close();
    this.$ws.socket.removeSubscription(
      `public_chat:${this.vendor_details.vendor_id}`
    );
  },
};
</script>

<style scoped>
.minimized-session {
  z-index: 5;
  position: fixed;
  right: 10px;
  bottom : 50px;
}
.chat-message {
  display: unset !important;
  white-space: break-spaces;
}
.chat-screen {
  overflow-y: auto;
  height: 350px;
  max-height: 350px;
}
.flex-none {
  flex: unset;
}
.received-message::after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: 54px;
  right: auto;
  top: 12px;
  bottom: auto;
  border: 12px solid;
  border-color: #4caf50 transparent transparent transparent;
}
.sent-message::after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 54px;
  top: 12px;
  bottom: auto;
  border: 12px solid;
  border-color: #80bc40 transparent transparent transparent;
}
textarea {
  line-height: 1.2rem !important;
}
</style>