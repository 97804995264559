<template>
  <v-container class="pa-0" fluid ref="vendor-booth-container">
    <v-app-bar
      color="primary"
      dense
      dark
      :max-width="maxWidth"
      class="mx-auto rounded-b-lg"
      fixed
    >
      <v-app-bar-nav-icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-app-bar-nav-icon>
      <v-card
        class="d-flex justify-space-between align-center"
        color="primary"
        flat
        tile
        width="100%"
      >
        <v-toolbar-title>{{ vendor_details.company_name }}'{{$t('vendor_booth.of_booth')}}</v-toolbar-title>
        <div>
          <v-btn
            color="orange"
            class="mx-2"
            small
            @click="showPublicChat"
          >
            <v-icon class="mr-1">mdi-chat</v-icon>
            {{$t('vendor_booth.public_chat')}}
          </v-btn>
          <v-menu
            offset-y
            v-if="respresentative.length >= 1"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="orange"
                dark
                v-bind="attrs"
                v-on="on"
                class="mx-2"
                small
              >
                <v-icon class="mr-1">mdi-comment-account</v-icon>
                {{$t('vendor_booth.live_chat_with_rep')}}
              </v-btn>
            </template>
            <v-list>
              <v-subheader
                >{{$t('common.representative')}} ({{ respresentative.length }})
              </v-subheader>
              <div style="max-height:400px;overflow-y:auto" class="bg-white">
                <v-list-item
                  v-for="(item, index) in respresentative"
                  :key="index"
                >
                  <v-badge
                    bordered
                    bottom
                    :color="item.attendee.status_color"
                    class="px-2"
                    dot
                    offset-x="20"
                    offset-y="10"
                  >
                    <v-avatar size="40">
                      <v-img :src="item.attendee.profile_pic" :lazy-src="FRONT_ASSETS + 'placeholder.png'"></v-img>
                    </v-avatar>
                  </v-badge>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span class="name">{{ item.attendee.first_name+' '+item.attendee.last_name }}</span>
                      <span class="text-caption" v-if="item.attendee.designation"
                        >( {{ item.attendee.designation }} )</span
                      >
                    </v-list-item-title>
                    <v-list-item-subtitle class="d-flex">
                      <span class="text-body-2">{{item.attendee.company_name}}</span>
                      <div v-if="item.attendee.langauge_knows" class="d-flex text-body-2 ml-2">
                        ( <span :title="flag" v-for="(flag,index) in item.attendee.langauge_knows" :key="index">{{flag}}<span v-if="index != (item.attendee.langauge_knows.length-1)" v-html="',&nbsp;'"></span></span> )
                      </div>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action class="d-flex flex-row">
                    <v-btn
                      icon
                      target="_blank"
                      :href="item.attendee.linkedin_url"
                      v-if="item.attendee.linkedin_url"
                    >
                      <v-icon color="light-blue darken-4">mdi-linkedin</v-icon>
                    </v-btn>
                    <v-tooltip
                      bottom
                      v-if="
                        myself.attendee_id != item.attendee_id &&
                          item.attendee.online_status == 1 &&
                          item.attendee.call_status === 'available'
                      "
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          :to="`/call/video?joineeId=${item.attendee_id}`"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon color="primary">mdi-phone</v-icon>
                        </v-btn>
                      </template>
                      <span>Make Video Call</span>
                    </v-tooltip>
                    <v-btn icon @click="startChatWithAttendee(item.attendee)">
                      <v-icon color="grey darken-3"
                        >mdi-message-text-outline</v-icon
                      >
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </div>
            </v-list>
          </v-menu>
          <v-menu
            offset-y
            v-if="respresentative.length >= 1"
            :close-on-content-click="false"
            v-model="schedule_chat_menu"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="orange"
                dark
                v-bind="attrs"
                v-on="on"
                class="mx-2"
                small
              >
                <v-icon class="mr-1">mdi-calendar-clock</v-icon>
                 {{$t('vendor_booth.schedule_chat')}}
              </v-btn>
            </template>
            <v-card>
              <v-card-text class="pa-1">
                <v-textarea
                  placeholder="Message"
                  outlined
                  v-model="pre_defined_message"
                  rows="5"
                  :hint="$t('vendor_booth.enter_to_send')"
                  @keyup.enter.exact="sendScheduleMessage()"
                  persistent-hint
                  :loading="send_loading"
                ></v-textarea>
              </v-card-text>
            </v-card>
          </v-menu>
          <v-menu right bottom min-width="365" v-model="visitor_menu" offset-y max-width="365" v-if="iAmReprsentative">
            <template v-slot:activator="{ on : on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                dark
              >
               <v-badge color="green" :content="booth_visitors.length" overlap bottom>
                 <v-icon>mdi-comment-account-outline</v-icon>
               </v-badge>
              </v-btn>
            </template>

            <v-list style="max-height:350px;overflow-y:auto">
              <span  v-for="(item, index) in booth_visitors" :key="index">
              <v-list-item class="px-2">
                <v-list-item-content class="py-1">
                  <v-list-item-title>
                    <span class="name">{{ item.attendee.first_name+' '+item.attendee.last_name }} {{$t('vendor_booth.booth_visited')}}</span>
                  </v-list-item-title>
                  <v-list-item-subtitle>{{$t('vendor_booth.last_visited')}} : {{ moment(item.updated_at).format('DD-MM-YYYY hh:mm A') }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon @click="startChatWithAttendee(item.attendee)" class="col-1">
                    <v-icon color="grey darken-3">mdi-message-text-outline</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-divider v-if="(booth_visitors.length-1)!=index"></v-divider>
              </span>
            </v-list>
          </v-menu>
        </div>
      </v-card>
    </v-app-bar>
    <basic-booth v-if="vendor_details.sponsor_type == 3"  />
    <gold-booth v-if="vendor_details.sponsor_type == 4" />
    <diamond-booth v-if="vendor_details.sponsor_type == 6" />
    <!-- <booth-details-image /> -->
    <PublicChat v-if="show_public_chat_modal" />
    <div style="position:fixed;bottom:60px;left:50%;transform: translateX(-50%);" v-if="respresentative_languages.length">
      <v-alert
        color="primary"
        dense
        icon="mdi-translate"
        dark
        max-width="450"
        dismissible
      >
        Preferred Languages Spoken: {{respresentative_languages.join(', ')}}
      </v-alert>
    </div>
    <v-btn
      elevation="2"
      color="orange"
      dark
      fixed
      bottom
      right
      to="/app"
    >
      <v-icon left>mdi-arrow-left</v-icon>
      <span>Exit Booth</span>
    </v-btn>
  </v-container>
</template>
<script>
import BasicBooth from "@/components/vendor-hall/BasicBooth";
import GoldBooth from "@/components/vendor-hall/GoldBooth";
import DiamondBooth from "@/components/vendor-hall/DiamondBooth";
import PublicChat from "@/components/vendor-hall/PublicChat";
import axios from "@/helper/axios";
import { mapState, mapMutations, mapActions } from "vuex";
import { getCallStatusCode } from "../../helper/attendee";

export default {
  components: {
    BasicBooth,
    GoldBooth,
    DiamondBooth,
    PublicChat,
  },
  data() {
    return {
      public_chat: true,
      subscription: null,
      loading: false,
      preview_data: null,
      show_preview: false,
      show_desc_modal: false,
      respresentative: [],
      send_loading: false,
      pre_defined_message: "Hello, I can't talk now but would like to chat later. Please message me back so we can schedule a time. Thanks!",
      schedule_chat_menu: false,
      booth_visitors : [],
      visitor_menu : false,
      beforeunloadFired: false
    };
  },
  computed: {
    ...mapState("vendor", ["vendor_details"]),
    ...mapState("public_chat", ["show_public_chat_modal"]),
    ...mapState("utils", ["maxWidth"]),
    myself() {
      return JSON.parse(localStorage.getItem("pinnacle_user"));
    },
    respresentative_languages() {
      let langauges = []
      if(this.respresentative){
        this.respresentative.map((attendee)=>{
          if(attendee.attendee.langauge_knows){
            let langs = attendee.attendee.langauge_knows
            langs.map((lang)=>{
              langauges.push(lang)
            })
          }
        })
      }
      if(langauges.length){
        langauges = langauges.filter(function(item, pos, self) {
            return self.indexOf(item) == pos;
        })
      }
      return langauges
    },
    iAmReprsentative(){
      let repsLength = this.respresentative.filter((repdata) => {
        return repdata.attendee_id == this.myself.attendee_id
      })
      return repsLength.length == 1
    }
  },
  watch : {
    visitor_menu : function(val){
      if(val){
        this.getBoothVisitor()
      }
    }
  },
  methods: {
    ...mapMutations("notifications", [
      "setExpandBox",
      "setTempPrivateMessage",
      "setChatPrivate",
      "setPrivateChatUser",
    ]),
    ...mapMutations("public_chat", ["setPublicChatData"]),
    ...mapActions("vendor", ["fetchVendor"]),
    getVendorDetails() {
      let _self = this;
      axios
        .get("/vendor/details?vendor_id=" + this.$route.params.vendor_id)
        .then(function (response) {
          _self.vendor_details = response.data.data;
        })
        .catch(function (e) {
          console.log(e);
        });
      console.log(_self.vendor_details);
      _self.loading = false;
    },
    addBoothView() {
      let _self = this;
      axios
        .post("/booth/add_view",{vendor_id: this.$route.params.vendor_id})
        .then(function () {

        })
        .catch(function (e) {
          console.log(e);
        });
      _self.loading = false;
    },
    addBoothViewTime() {
      this.beforeunloadFired = true
      let _self = this;
      axios
        .post("/booth/add_view_time",{vendor_id: this.vendor_id, start_timestamp: this.start_timestamp, end_timestamp: this.moment().format('YYYY-MM-DD HH:mm:ss')})
        .then(function () {

        })
        .catch(function (e) {
          console.log(e);
        });
      _self.loading = false;
    },
    getRespresentative() {
      let _self = this;
      axios
        .get("/representative/list?vendor_id=" + this.$route.params.vendor_id)
        .then(function (response) {
          _self.respresentative = response.data.data;
        })
        .catch(function (e) {
          console.log(e);
        });
      _self.loading = false;
    },
    startChatWithAttendee(attendee) {
      this.setChatPrivate(true);
      this.setTempPrivateMessage(attendee);
      this.setPrivateChatUser(attendee);
      this.setExpandBox(0);
    },
    sendScheduleMessage() {
      if (this.pre_defined_message.length) {
        this.send_loading = true;
        const findRepWithPriority = this.respresentative.filter((rep) => {
          return rep.is_contact_first == 1
        })
        let rep = 0
        if(findRepWithPriority.length) {
          if(findRepWithPriority.length > 1){
            rep = findRepWithPriority[0].attendee.attendee_id
          } else {
            rep = findRepWithPriority[0].attendee.attendee_id
          }
        } else {
          rep = this.respresentative[0].attendee.attendee_id
        }
        if(rep == 0){return}
        let data = {
          message: this.pre_defined_message,
          receive_by: rep,
          is_schedule_msg: 1
        };
        axios
          .post("/attendee/add_message", data)
          .then(() => {
            this.schedule_chat_menu = false;
          })
          .then(() => {
            this.send_loading = false;
          });
      }
    },
    showPublicChat() {
      this.setPublicChatData({
        key: "show_public_chat_modal",
        data: true,
      });
    },
    getBoothVisitor(){
      axios.get("/booth/get_view?vendor_id=" + this.$route.params.vendor_id)
      .then((res)=> {
        let data = res.data.data
        this.booth_visitors = data
      })
      // axios.get()
      // .then((response) => {
      //   console.log(response.data.data)
      //   this.booth_visitors = response.data.data;
      // })
      // .catch(function (e) {
      //   console.log(e);
      // });
    },
    handleAttendeeStatusEvents(data) {
      if (data.attendee_id && Array.isArray(this.respresentative)) {
        const attendeeIndex = this.respresentative.findIndex(attendee => {
          return attendee.attendee_id == Number(data.attendee_id);
        })

        if (attendeeIndex != -1) {
          const tempRepresentatives = [...this.respresentative];

          if (tempRepresentatives[attendeeIndex].attendee) {
            tempRepresentatives[attendeeIndex].attendee.call_status = getCallStatusCode(data.call_status);
            tempRepresentatives[attendeeIndex].attendee.online_status = Number(data.online_status);
            tempRepresentatives[attendeeIndex].attendee.status_color = Number(data.online_status) == 1 ? 'green' : 'red';
          }

          this.respresentative = tempRepresentatives;
        }
      }
    },
    subscribeToChannels() {
      let subscription = this.$ws.socket.getSubscription("attendee_status");

      if (!subscription) {
        subscription = this.$ws.subscribe("attendee_status");

        this.$ws.$on("attendee_status", this.handleAttendeeStatusEvents);
      } else {
        this.$ws.$on("attendee_status", this.handleAttendeeStatusEvents);
      }
    },
    unsubscribeFromChannels() {
      this.$ws.$off("attendee_status", this.handleAttendeeStatusEvents);
    },
  },
  created() {
    window.addEventListener('beforeunload', () => {
      if(this.beforeunloadFired == false) {
        this.addBoothViewTime();
      }
    })
  },
  mounted() {
    this.subscribeToChannels();
    this.start_timestamp = this.moment().format('YYYY-MM-DD HH:mm:ss');
    this.vendor_id = this.$route.params.vendor_id;
    this.fetchVendor(this.$route.params.vendor_id);
    this.getRespresentative();
    this.addBoothView()
    this.getBoothVisitor()
    this.$vuetify.goTo(this.$refs["vendor-booth-container"], {
      offset: -200,
    });
    this.$vuetify.goTo(this.$refs["vendor-booth-container"], {
      offset: -200,
    });
    this.subscription = this.$ws.socket.getSubscription(
      `user:${this.myself.attendee_id}`
    );
    if (!this.subscription) {
      this.subscription = this.$ws.subscribe(`user:${this.myself.attendee_id}`);
      this.subscription.emit("vendorvisit", {
        vendor: this.$route.params.vendor_id,
        visitor: this.myself.attendee_id,
      });
    } else {
      this.subscription.emit("vendorvisit", {
        vendor: this.$route.params.vendor_id,
        visitor: this.myself.attendee_id,
      });
    }
  },
  beforeDestroy(){
    this.addBoothViewTime();
    this.unsubscribeFromChannels()
  }
};
</script>
<style>
.custom_sub_html ol,.custom_sub_html ul{
  padding-left: 1.2vw !important;
}
.custom_sub_html  a {
  color: white !important;
}
</style>
